.box-dashboard {
  .ant-alert {
    margin-bottom: 24px;
  }

  .widget {
    border-radius: 5px;
    margin-bottom: 24px;
    width: 100%;
    background-color: #fff;
    > .ant-card-head {
      .ant-card-head-title {
        color: rgba(0, 0, 0, 0.65);
      }
    }
    .ant-btn:disabled {
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(1, 1, 1, 0.6);
    }
    &.widget-broker-form {
      .ant-card-body {
        .ant-btn {
          margin-top: 42px;
        }
      }
    }
  }
}

.text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leading-zeroes {
  opacity: 50%;
}

.member-overview-modal {
  .personal-info-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    > div {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .avatar {
      max-width: 200px;
      img {
        width: 100%;
        border-radius: 50%;
        background: #cecece;
      }
    }
  }
  // .personal-info-fields {
  //   padding-right: 20px;
  // }
  .stake-section {
    padding: 16px;
  }
}

.ant-table {
  td > p, th > p {
    margin-bottom: 0;
  }
}

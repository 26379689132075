%align-center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%align-vertical {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

%align-horizontal {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

//portal
.modal-portal,
.ReactModalPortal {
  position: absolute;
  left: 0;
  top: 0;
}

//remove menu quando modal esta ativo e desabilita o scroll
.ReactModal__Body--open {
  overflow: hidden !important;

  footer {
    display: none;
  }
}

//Colors base
$ts1: #0e121c;
$ts2: #1e2332;
$ts3: #5d6579;
$ts10: #ffffff;
$ts11: transparent;
//Colors
$success: #27ae60;
$danger: #ff4f4f;
$info: #faa61a;
$primary: #036cbc;
$warning: #f6891e;

//Breakpoints

$breakpoint-width-xxs: 400px;
$breakpoint-width-xs: 500px;
$breakpoint-width-s: 600px;
$breakpoint-width-m: 820px;
$breakpoint-width-l: 1000px;
$breakpoint-width-xl: 1080px;
$breakpoint-width-xxl: 1281px;

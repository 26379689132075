//loading route
.overlay-route {
  position: fixed;
  height: 100%;
  width: 100%;
  @include background-opacity($ts1, 0.7);
  z-index: 3;
  overflow-y: auto;
  > img {
    width: 100px;
    height: 100px;
    @extend %align-center;
  }
}

.loading-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 3;
  overflow-y: auto;
  .ant-spin {
    @extend %align-center;
  }
}

//border opacity
@mixin border-opacity($color, $opacity) {
  border-color: rgba($color, $opacity);
}
//background opacity
@mixin background-opacity($color, $opacity) {
  background-color: rgba($color, $opacity);
}
//color opacity
@mixin color-opacity($color, $opacity) {
  color: rgba($color, $opacity);
}

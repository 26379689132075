#root {
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
}

.auth-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  .box-login {
    .logo {
      @extend %align-center;
      width: 300px;
      padding: 0 20px;

      img {
        width: 260px;
      }
    }

    .login-form {
      max-width: 300px;
    }

    .login-form-forgot {
      float: right;
    }

    .login-form-button,
    .login-form-button-inkryptus {
      width: 100%;
    }

    .login-form-button-inkryptus {
      background-color: $primary;
      border-color: $primary;
    }
  }
}

.dashboard-layout {
  min-height: 100vh;
  background: none;
}

.dashboard-header {
  background-color: #fff;
  position: fixed;
  z-index: 1;
  width: 100%;
  line-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;

  > div:nth-child(1) {
    width: 180px;
    margin-right: 20px;
    cursor: pointer;

    > img {
      width: 180px;
    }
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.dashboard-content {
  margin-top: 64px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    padding: 20px 50px;
    width: 100%;
    max-width: 1300px;
    flex: 1;

    &.full-width {
      max-width: unset !important;
    }
  }
}

.dashboard-footer {
  text-align: center;
  background: none;
  color: rgba(255, 255, 255, 0.65);
}

.admin-layout {
  min-height: 100vh;
}

.admin-header {
  background-color: #001529;
  position: fixed;
  z-index: 1;
  width: 100%;
  line-height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;

  > div:nth-child(1) {
    width: 180px;
    margin-right: 20px;
    cursor: pointer;

    > img {
      width: 180px;
    }
  }

  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.admin-content {
  margin-top: 64px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > div {
    // padding: 20px 50px;
    width: 100%;
    max-width: 1300px;
    flex: 1;

    &.full-width {
      max-width: unset !important;
    }
  }
}

.admin-footer {
  text-align: center;
  color: rgba(1, 1, 1, 0.55);
}
